import React from 'react';
import { graphql } from 'gatsby';
import loadable from '@loadable/component';

import { makeStyles, Container, useMediaQuery } from '@material-ui/core';

import { Hero } from '../components/General/Hero';
import { WaveUpSVG } from '../components/WaveSVGs/WaveUpSVG';
import { SEO } from '../components/SEO';
import { ProfessionalServicesBody } from '../components/ProfessionalServices/ProfessionalServicesBody';
import { Testimonials } from '../components/Testimonials';
import { CustomerLogos } from '../components/CustomerLogos';
import { WaveDownSVG } from '../components/WaveSVGs/WaveDownSVG';
import { Resources } from '../components/General/Resources';
import { ProfServExternalContactForm } from '../components/ProfessionalServices/ProfServExternalContactForm';
import { Intro } from '../components/ProfessionalServices/Intro';

const Form = loadable(() => import('../components/ContactForm/Form'));

const useStyles = makeStyles((theme) => ({
	testimonialBackground: {
		backgroundSize: 'cover',
		height: '600px',
		width: '99vw',
		maxWidth: '100%',
		[theme.breakpoints.down('md')]: {
			width: '100vw',
			height: '500px',
		},
		[theme.breakpoints.down('sm')]: {
			height: '400px',
		},
		[theme.breakpoints.down('xs')]: {
			backgroundPosition: 'left 60px',
		},
	},
	testimonialContainer: {
		marginTop: '-35rem',
		[theme.breakpoints.down('md')]: {
			marginTop: '-30rem',
		},
		[theme.breakpoints.down('sm')]: {
			marginTop: '-25rem',
		},
	},
	testimonialTitle: {
		color: theme.white,
		fontWeight: 700,
		padding: '2rem 0',
	},
	formCont: {
		padding: '10rem 0 24rem 0',
		[theme.breakpoints.down('md')]: {
			padding: '10rem 0',
		},
		[theme.breakpoints.down('xs')]: {
			padding: '8rem 0',
		},
	},
}));

const ProfessionalServices = ({ data, location }) => {
	const professionalServices = data.professionalServices.edges[0].node;
	const classes = useStyles();

	const {
		hero,
		metaDescription,
		metaTitle,
		pardotUrl,
		contactForm,
		bodyHeader,
		professionalServicesCards,
		formBgImage,
		testimonial,
		testimonialTitle,
		customerLogos,
		customerLogosHeader,
		resources,
		resourceTitle,
		_rawResourceBody,
		testimonialBackground,
		_rawIntro,
		introImage,
		externalForm,
	} = professionalServices;

	return (
		<>
			<SEO title={metaTitle} description={metaDescription} />
			<Hero hero={hero} />
			<Intro intro={_rawIntro} introImage={introImage} />
			<ProfessionalServicesBody
				header={bodyHeader}
				cards={professionalServicesCards}
			/>
			<>
				<div
					className={classes.testimonialBackground}
					style={{
						backgroundImage: `url(${testimonialBackground.asset.gatsbyImageData.images.fallback.src})`,
					}}
				/>
				<WaveUpSVG fill='#FFF' />
				<div className={classes.testimonialContainer}>
					<Testimonials
						header={testimonialTitle}
						testimonials={testimonial}
						background
					/>
				</div>
			</>
			<Container style={{ marginBottom: '7rem' }}>
				<CustomerLogos
					customerLogosArray={customerLogos}
					customerLogosHeader={customerLogosHeader}
				/>
			</Container>
			{/* <WaveDownSVG height='213' width='100%' fill='white' /> */}

			<div
				className={classes.formCont}
				style={{
					backgroundImage: `url(${formBgImage?.asset?.gatsbyImageData?.images.fallback.src})`,
					backgroundSize: 'cover',
				}}>
				<ProfServExternalContactForm
					formValues={externalForm}
					contactForm={contactForm}
					// _rawFormFooterBody={_rawFormFooterBody}
					endPoint='https://workwave.my.salesforce-sites.com/web2case/services/apexrest/cases/create'
					//endPoint='https://webto.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8'
				/>
				<Form privacy modal={false} noForm={true} location={location} />
			</div>
			{/* <WaveUpSVG height='213' width='100%' fill='white' /> */}
			<Container>
				<Resources
					header={resourceTitle}
					subheader={_rawResourceBody}
					resources={resources}
					noWave
				/>
			</Container>
			<div>
				<WaveUpSVG height='213' width='100%' fill='#f4f8ff' />
			</div>
		</>
	);
};

export const query = graphql`
	query ProfessionalServicesQuery {
		professionalServices: allSanityProfessionalServices {
			edges {
				node {
					title
					metaTitle
					metaDescription
					hero {
						_rawContent
						backgroundImage {
							asset {
								gatsbyImageData(placeholder: BLURRED, height: 100)
							}
						}
						mobileBackgroundImage {
							asset {
								gatsbyImageData(placeholder: BLURRED)
							}
						}
						ctaButtonText
						ctaButtonLink
						internalLink
					}
					heroImage {
						asset {
							gatsbyImageData(placeholder: BLURRED)
						}
					}
					heroVideo
					heroAlignCenter
					_rawIntro
					introImage {
						asset {
							gatsbyImageData(placeholder: BLURRED, width: 410)
						}
					}
					resourceTitle
					bodyHeader
					professionalServicesCards {
						title
						icon
						header
						subheader
						ctaText
						link
						_rawBody
						accentColor {
							hexValue
						}
					}
					testimonialTitle
					testimonial {
						title
						header
						testimonialLogo {
							asset {
								gatsbyImageData(placeholder: BLURRED)
							}
						}
						company
						testimonialText
						nameAndTitle
						videoVariant
						image {
							asset {
								gatsbyImageData(
									fit: FILLMAX
									height: 450
									width: 775
									placeholder: BLURRED
								)
							}
						}
					}
					testimonialBackground {
						asset {
							gatsbyImageData(placeholder: BLURRED)
						}
					}
					customerLogosHeader
					customerLogos {
						logo {
							asset {
								gatsbyImageData(placeholder: BLURRED)
							}
						}
					}
					_rawResourceBody
					resources {
						title
						blurb
						image {
							asset {
								gatsbyImageData(placeholder: BLURRED)
							}
						}
						ctaText
						ctaLink
					}
					contactForm {
						header
						privacyPolicy
					}
					formBgImage {
						asset {
							gatsbyImageData
						}
					}
					externalForm {
						fieldLabel
						fieldValue
						textArea
						externalFormSelect {
							fieldLabel
							fieldValue
						}
					}
					pardotUrl
				}
			}
		}
	}
`;

export default ProfessionalServices;
